.paymentsHistory {
  tr td,
  tr th {
    border-right: 1px solid rgba(224, 224, 224, 1);
    min-width: 170px;
    padding: 0 10px;
  }

  td:last-child,
  th:last-child {
    min-width: 44px;
  }

  tr th {
    padding: 10px 10px;
    background-color: rgba(224, 224, 224, 0.5);
  }

  td button {
    padding: 10px;
  }
  td:has(button) {
    padding: 0;
  }
}
